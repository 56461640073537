

import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import { Button, Modal, Input, message } from 'antd';
import { postData } from './FetchAPI';
import { Avatar, Card } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import { Col, Divider, Row } from 'antd';
import { Table } from 'antd';
import { Document, Page } from 'react-pdf';
import { useMatch, useNavigate } from 'react-router';
import { Result } from 'antd';
import { Upload } from 'antd';
import { CoffeeOutlined } from '@ant-design/icons';
import { Editor } from '@tinymce/tinymce-react';
const { Header, Content, Sider } = Layout;
const { TextArea } = Input;
const { Meta } = Card;
const { Column } = Table;



function Recipe(props) {

  const navigate = useNavigate();

  const {
    token: { colorBgContainer, colorPrimaryActive },
  } = theme.useToken();

  const [bump, setBump] = useState(0);
  const [recipe, setRecipe] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [reviewText, setReviewText] = useState(null);

  const [currentUser, setCurrentUser] = useState(null);

  const recipe_id = useMatch('recipes/:recipe_id').params.recipe_id;

  // These are used for Image upload
  const imageUrl = null;
  const loading = false;
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload an image</div>
    </div>
  );


  useEffect(() => {
    const loginPromise = fetch('/api/login/');
    loginPromise.then(response => {
        return response.json();
      }).then(loggedInUser => {
        setCurrentUser(loggedInUser["username"])
      });
  }, []);


  
  useEffect(() => {
    const recipePromise = fetch(`/api/recipes/${recipe_id}`);
    recipePromise.then(response => {
        return response.json();
      }).then(recipes => {
        setRecipe(recipes)
      });
  }, [bump]);

  useEffect(() => {
    const reviewsPromise = fetch(`/api/reviews/?recipe_id=${recipe_id}`);
    reviewsPromise.then(response => {
        return response.json();
      }).then(recipes => {
        setReviews(recipes)
      });
  }, [bump]);

  const showReviewModal = () => { 
    setIsReviewModalOpen(true);
  };

  const handleReviewModalOk = async (data) => {
    setIsReviewModalOpen(false);
    await postData('/api/reviews/', {recipe:recipe_id, review: reviewText});
    setBump(bump+1)
    message.success("Review Added", 8)
  };

  const handleReviewModalCancel = () => {
    setIsReviewModalOpen(false);
  };


  return (
    <Layout>
      <Header style={{display: 'flex', alignItems: 'center' }}>
        <div style={{color: colorPrimaryActive}} className="demo-logo" />
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
            <Menu.Item key="1" onClick={() => navigate(`/recipes/`)}>Recipes</Menu.Item>
            <Menu.Item key="2" onClick={() => navigate(`/timeline/`)}>Timeline</Menu.Item>
            <Menu.Item key="3" onClick={() => navigate(`/users/`)}>Users</Menu.Item>
            {/* <Menu.Item >
              <Button type="primary" onClick={showRecipeModal}> 
              Add Recipe
              </Button>
            </Menu.Item> */}

        </Menu>
      </Header>
      <Layout style={{minHeight: '800px'}}>
      {/* <Modal title="Add Recipe" open={isRecipeModalOpen} onOk={handleRecipeModalOk} onCancel={handleRecipeModalCancel}>
          <Input placeholder="URL" onChange={e => setUrl(e.target.value)} />
        </Modal> */}
        <Modal title={recipe.title} open={isReviewModalOpen} onOk={handleReviewModalOk} onCancel={handleReviewModalCancel}>
          <h3>What did you think of this recipe?</h3>
          <h4>Did you like it? Did you make any changes or substitutions? Would you do anything different next time?</h4>
          {!currentUser && (<div style={{color: 'red'}}>Please <a href="/users/">login</a> to continue</div>)}
          {currentUser && (<TextArea rows={4} placeholder="Write Your Review"  onChange={e => setReviewText(e.target.value)} />)}
        </Modal>

        <Layout style={{ padding: '0 24px 24px' }}>
        {/* <h3>{currentUser && `Hello ${currentUser}!`}</h3> */}
          <Content
            style={{
              // display: 'flex', 
              // alignItems: 'center',
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
            }}
          >
            <Row>
              <Col span={24}>
              <Result
                style={{width: "100%"}}
                icon={<img style={{height: "100px", width: "150px"}} src="https://bucketeer-342c25ac-45d7-4802-b2b8-e36138b7167b.s3.amazonaws.com/local-recipes/egg-in-pan.png" />}
                title="Did you cook this recipe? Log it and Leave a Review!"
                extra={<Button type="primary"  onClick={showReviewModal}>I cooked this!</Button>}
              />

              </Col>
            </Row>
          <Row >
            <Col span={12}>
          {recipe && (
            <div>

              <h1 style={{textAlign: "center"}}>{recipe.url ? <a target="_blank" href={recipe.url}>{recipe.title}</a> : recipe.title}</h1>
              <h3 style={{textAlign: "center"}}>{`Submitted by ${recipe?.submitter?.username}`}</h3>
              <div style={{width: "100%", textAlign: "center"}}>
              {recipe.top_image && (<img style={{maxWidth: "400px", maxHeight: "267px"}} alt="recipe image" src={recipe.top_image} />)}
              {!recipe.top_image && (
                    <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={`/api/recipe-top-image/${recipe.id}`}
                    onChange={() => setBump(bump+1)}
                  >
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                  </Upload>            
              
              )}
              </div>
            </div>
            )}
          {reviews.map((review => {
            return <div><Card
            extra={review.created_date_ET}
            style={{ maxWidth: "800px", margin: '20px' }}
            title={<p>{review?.submitter?.username} cooked <b><a href={`/recipes/${review.recipe.id}/`}>{review.recipe.title}</a></b></p>}
            description={`reviewed by ${review?.recipe?.submitter}`}
            
          >
            <Meta 
            title={<div style={{overflow: "visible", whiteSpace: "normal"}}>{`"${review.review}"`}</div>} 
            description={`Reviewed by ${recipe.submitter.username}`}
            avatar={<Avatar size={64} src={review.submitter.avatar_png || `https://api.dicebear.com/6.x/bottts/svg?seed=${review.submitter.username}`} />}
            />
          </Card></div>
        }))}
            </Col>
            <Col span={12} style={{display: 'flex', alignItems: 'center', flexWrap: "wrap", justifyContent: "center"}}>
              {recipe.png_count && (
                <>
                {[...Array(recipe.png_count).keys()].map((i) =>  (
                    <img style={{maxWidth: "600px"}} src={`${recipe.png_base_link}_${i+1}.png`} />    
                ))}
                </>
              )}
              {recipe.recipe_text_html && (
                <Editor 
                style={{marginLeft: "20px", marginRight: "20px"}}
                init={{
                  menubar: false,
                  toolbar: false,
                  height: "1000px",
                  width: "100%",
                }}
                disabled={true}
                initialValue={recipe.recipe_text_html} 
                apiKey="yeihiqwyp69vo8jiyufbpp2w2x49cvxzqa0txzprorzln1zh" 
                />  
              )}
            </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Recipe;
