import logo from './logo.svg';
import './App.css';
import { useDebounce } from "use-debounce";
import { useEffect, useRef, useState } from 'react';
import { Button, Modal, Input, message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { postData } from './FetchAPI';
import { Avatar, Card } from 'antd';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import { Col, Divider, Row } from 'antd';
import { Table } from 'antd';
import { useLocation, useNavigate } from 'react-router';
import { Timeline } from 'antd';
import { Pagination } from 'antd';
import { Switch } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
const { Header, Content, Sider } = Layout;
const { TextArea } = Input;
const { Meta } = Card;
const { Column } = Table;


const template = `<h2>Recipe Title</h2>
<hr>
<h3>Ingredients:</h3>
<ul>
<li>1 cup of ingredient 1</li>
<li>2 tbsp ingredient 2</li>
<li>a dash of ingredient 3</li>
</ul>
<hr>
<h3>Instructions</h3>
<h4>Step 1.</h4>
<p>Pre-heat the oven to 350</p>
<h4>Step 2.</h4>
<p>Bake for 3 hours</p>
<h4>Step 3.</h4>
<p>Eat it</p>`


function App(props) {

  const navigate = useNavigate()

  const location = useLocation()

  useEffect(() => {
    if (location.pathname == "/recipes/") {
      setNavKey("1")
    }
    else if (location.pathname == "/timeline/") {
      setNavKey("2")
    }
    else if (location.pathname == "/users/") {
      setNavKey("3")
    }

  }, [location])

  const editorRef = useRef(null);

  const {
    token: { colorBgContainer, colorPrimaryActive },
  } = theme.useToken();

  const [recipes, setRecipes] = useState([]);
  const [reviews, setReviews] = useState([]);

  const [isRecipeModalOpen, setIsRecipeModalOpen] = useState(false);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);

  // This is used for submitting a recipe
  const [url, setUrl] = useState(null);
  // const [title, setTitle] = useState(null);
  const [recipeToggleURL, setRecipeToggleURL] = useState(true)

  // These are used for reviews
  const [reviewText, setReviewText] = useState(null);
  const [selectedRecipe, setSelectedRecipe] = useState(null);

  // These are used for users
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [newUser, setNewUser] = useState(null);


  // These are used for Search
  const [search, setSearch] = useState('');
  const [debouncedValue] = useDebounce(search, 500);
  const [blur, setBlur] = useState(false);

    // These are used for Image upload
    const imageUrl = null;
    const loading = false;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload an image</div>
      </div>
    );

  const [bump, setBump] = useState(0);

  const [navKey, setNavKey] = useState("1")


  const showRecipeModal = () => { 
    if (!isRecipeModalOpen) {
      setIsRecipeModalOpen(true);
    }
    
  };

  const handleRecipeModalOk = async (data) => {
    setIsRecipeModalOpen(false);
    let recipeEndpoint = '/api/recipe/';
    let recipeTextHTML = null
    if (!url) {
      recipeTextHTML = editorRef.current.getContent();
    }
    
    await postData(recipeEndpoint, {url:url, recipe_text_html: recipeTextHTML});
    setBump(bump+1);
    message.success("Recipe Added", 8);
  };

  const handleRecipeModalCancel = () => {
    if (isRecipeModalOpen) {
      setIsRecipeModalOpen(false);
    }
  };

  const showReviewModal = (recipe) => { 
    setSelectedRecipe(recipe);
    setIsReviewModalOpen(true);
  };

  const handleReviewModalOk = async () => {
    setIsReviewModalOpen(false);
    if (reviewText) {
      await postData('/api/reviews/', {recipe:selectedRecipe.id, review: reviewText});
      setBump(bump+1)
      message.success("Review Added", 8)  
    }
  };

  const handleReviewModalCancel = () => {
    setIsReviewModalOpen(false);
  };
  
  useEffect(() => {
    const loginPromise = fetch('/api/login/');
    loginPromise.then(response => {
        return response.json();
      }).then(loggedInUser => {
        setCurrentUser(loggedInUser["username"])
        if (!loggedInUser["username"]) {
          navigate('/users/');
        }    
      });
  }, []);

  useEffect(() => {
    let recipeEndpoint = '/api/recipes/'
    if (search) {
      recipeEndpoint = recipeEndpoint + `?search=${search}`
    }
    const recipesPromise = fetch(recipeEndpoint);
    recipesPromise.then(response => {
        return response.json();
      }).then(recipes => {
        setRecipes(recipes);
        setBlur(false);
      });
  }, [bump, debouncedValue]);

  useEffect(() => {
    const reviewsPromise = fetch('/api/reviews/');
    reviewsPromise.then(response => {
        return response.json();
      }).then(reviews => {
        setReviews(reviews)
      });
  }, [bump]);

  useEffect(() => {
    const usersPromise = fetch('/api/users/');
    usersPromise.then(response => {
        return response.json();
      }).then(users => {
        setUsers(users)
      });
  }, [bump]);

  const login = async (username) => {
    await postData('/api/login/', {username:username});
    setCurrentUser(username);
    message.success("You are logged in, get cooking!", 8)
  }

  const createUser = async () => {
    await postData('/api/users/', {username:newUser});
    await login(newUser);
    setBump(bump+1);
    message.success("User Created, Let's get cooking!", 8)
  }
  
  const onChangeRecipeToggle = () => {
    setRecipeToggleURL(!recipeToggleURL)
  }

  return (
    <Layout>
      <Header style={{display: 'flex', alignItems: 'center' }}>
        <div style={{color: colorPrimaryActive}} className="demo-logo" />
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
            <Menu.Item onClick={event => setNavKey(event.key)} key="1">Recipes</Menu.Item>
            <Menu.Item onClick={event => setNavKey(event.key)} key="2">Timeline</Menu.Item>
            <Menu.Item onClick={event => setNavKey(event.key)} key="3">Users</Menu.Item>
            <Menu.Item >
              <Button type="primary" onClick={showRecipeModal}> 
              Add Recipe
              </Button>
            </Menu.Item>

        </Menu>
      </Header>
      <Layout style={{minHeight: '800px'}}>
      <Modal title="Add Recipe" open={isRecipeModalOpen} onOk={handleRecipeModalOk} onCancel={handleRecipeModalCancel}>
        Enter the URL of the recipe, or type in a custom recipe
        <div style={{margin: "10px"}}>
          {!currentUser && (<div style={{color: 'red'}}>Please <a href="/users/">login</a> to continue</div>)}
          <div style={{margin: "10px"}}>
          Custom Recipe<Switch style={{margin: "10px"}} checked={recipeToggleURL} onChange={onChangeRecipeToggle} /> Enter a URL
          </div>
          {recipeToggleURL && (
            <Input disabled={!currentUser} placeholder="URL" onChange={e => setUrl(e.target.value)} />
          )}
          {!recipeToggleURL && (
            <>
            {/* <Input disabled={!currentUser} placeholder="Title" onChange={e => setTitle(e.target.value)} /> */}
            <Editor disabled={!currentUser} init={{branding: false}} initialValue={template} apiKey="yeihiqwyp69vo8jiyufbpp2w2x49cvxzqa0txzprorzln1zh" onInit={(evt, editor) => editorRef.current = editor} />  
            </>
          )}
          </div>
        </Modal>

        <Layout style={{ padding: '0 24px 24px' }}>
        <h3>{currentUser && `Hello ${currentUser}!`}</h3>
          {/* <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>Recipes</Breadcrumb.Item>
          </Breadcrumb> */}
          {navKey === "1" && ( <>
          Search: <Input style={{maxWidth: "250px"}} onChange={e => {setBlur(true);setSearch(e.target.value)}}></Input>
          </>
          )}
          <Content
            style={{
              // display: 'flex', 
              // alignItems: 'center',
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
            }}
          >
        {navKey === "1" && ( 
          <div style={{display: 'flex', alignItems: 'center', flexWrap: "wrap", opacity: `${blur ? ".7": "1"}`}} >
            <Card
              hoverable
              style={{ width: 480, height: 400, margin: '20px' }}
              cover={<img style={{maxWidth: "400px", maxHeight: "267px"}} alt="example" src="https://bucketeer-342c25ac-45d7-4802-b2b8-e36138b7167b.s3.amazonaws.com/local-recipes/add-recipe.png" />}
              onClick={showRecipeModal}
              title=""
            >
              <Meta title={"Add a new recipe!"} description={"Click Here"} />
            </Card>
          {recipes.map((recipe => {
              return <Card
              key={recipe.id}
              hoverable
              style={{ width: 480, height: 400, margin: '20px' }}
              cover={<img style={{maxWidth: "400px", maxHeight: "267px"}} alt="example" src={recipe.top_image} />}
              onClick={() => navigate(`/recipes/${recipe.id}/`)}
              // actions={[
              //   <div onClick={() => showReviewModal(recipe)} >Add Review <SettingOutlined key="setting"/></div>,
              // ]}
            >
              <Meta title={recipe.title} description={`Submitted by ${recipe?.submitter?.username}`} />
            </Card>
          }))
        }</div>
        )}
        {navKey === "2" && ( 
          
          <div>
            <h2>Timeline of recent recipes the fam has cooked</h2>
            <Timeline >
            {
          reviews.map((review => {
            return <Timeline.Item><div>{review.created_date_ET}<Card
            extra={""}
            style={{ maxWidth: "800px", margin: '20px' }}
            title={<div>{review?.submitter?.username} cooked <b><a href={`/recipes/${review.recipe.id}/`}>{review.recipe.title}</a></b></div>}
            
          >
            <Meta 
            title={<div style={{overflow: "visible", whiteSpace: "normal"}}>{`"${review.review}"`}</div>} 
            description={`reviewed by ${review.submitter.username}`}
            avatar={<Avatar size={64} src={review.submitter.avatar_png || `https://api.dicebear.com/6.x/bottts/svg?seed=${review.submitter.username}`} />}
            />
          </Card>
          {/* <img style={{maxWidth: "200px", maxHeight: "100"}} alt="example" src={review.recipe.top_image} /> */}
            </div>
            </Timeline.Item>
        }))
      }</Timeline></div>)}
        {navKey === "3" && ( 
          <>
          <h3>Welcome, please log in with your first name to continue</h3>
          <Table dataSource={users}>
            <Column title="First Name" dataIndex="username" key="firstName" />
            <Column title="Log In" key="login" 
            render={(user) => (
                  <Button onClick={e => login(user.username)} type="primary">Log in</Button>
              )}
            /> 
            <Column title="Avatar"
              render = {(user) => 
                (<div style={{display: "flex"}}>
                  {user.avatar_png && (<img style={{maxWidth: "150px", maxHeight: "150"}} alt="avatar" src={user.avatar_png} />)}
                    <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={`/api/users/${user.id}/upload_avatar/`}
                    onChange={() => setBump(bump+1)}
                  >
                    {uploadButton}
                  </Upload>

                  </div>)
              }
            />
          </Table>
          <div>
            Don't see your name? Add yourself!
            <Input style={{maxWidth: "250px"}} placeholder="Your First Name" onChange={e => setNewUser(e.target.value)} />
            <Button onClick={async e => await createUser()} type="primary">Create User</Button>
          </div>
          </>
        )}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default App;
